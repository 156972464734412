<template>
    <div :class="containerClass">
        <div class="layout-topbar">
            <div class="topbar-logo">
                <router-link to="/">
                    <img :src="$appState.dark ? 'images/logo.png': 'images/logo.png'" class="logo" alt="Conex Logo">
                    <img src="images/logo.png" class="icon" alt="Conex">
                </router-link>
            </div>

            <div class="topbar-actions">
                <a class="topbar-menu-button" @click="showTopbarMenu($event)">
                    <i class="pi pi-bars"></i>
                </a>
                <ul class="topbar-menu">
                    <li><router-link to="/">BLOCKS</router-link></li>
                    <li><router-link to="/documentation">DOCUMENTATION</router-link></li>
                </ul>
            </div>
        </div>

        <div class="layout-breadcrumb">
            <ul>
                <li>
                    <router-link to="/"><i class="pi pi-home"></i></router-link>
                    <div v-if="$route.path === '/'" class="chevron">/</div>
                    <template v-if="$route.meta.breadcrumb">
                        <template v-if="$route.meta.breadcrumb[0].parent">
                            <div v-if="$route.path !== '/'" class="chevron">/</div>
                            <router-link :to="parentRoute">
                                <span>{{$route.meta.breadcrumb[0].parent}}</span>
                            </router-link>
                        </template>
                        <div v-if="$route.path !== '/'" class="chevron">/</div>
                        <router-link v-if="$route.meta.breadcrumb[0].label" :to="$route.path">
                            <span>{{$route.meta.breadcrumb[0].label}}</span>
                        </router-link>
                    </template>
                </li>
            </ul>
        </div>

        <div class="layout-content">
            <router-view></router-view>
        </div>

        <Sidebar v-model:visible="visibleTopbarMenu" position="right">
            <ul class="topbar-mobile-menu">
                <li><router-link to="/">BLOCKS</router-link></li>
                <li><router-link to="/documentation">DOCUMENTATION</router-link></li>
            </ul>
        </Sidebar>
    </div>
</template>

<script>
export default {
    data() {
        return {
            visibleSidebar: false,
            visibleTopbarMenu: false,
            scale: 16,
            scales: [12,13,14,15,16]
        }
    },
    methods: {
        changeTheme(event, theme, dark) {
            let themeElement = document.getElementById('theme-link');
            themeElement.setAttribute('href', themeElement.getAttribute('href').replace(this.$appState.theme, theme));
            this.$appState.theme = theme;
            this.$appState.dark = dark;
            event.preventDefault();
        },
        decrementScale() {
            this.scale--;
            this.applyScale();
        },
        incrementScale() {
            this.scale++;
            this.applyScale();
        },
        applyScale() {
            document.documentElement.style.fontSize = this.scale + 'px';
        },
        showTopbarMenu(event) {
            this.visibleTopbarMenu = true;
            event.preventDefault();
        }
    },
    computed: {
        containerClass() {
            return [
                'layout-wrapper', {
                    "layout-wrapper-dark": this.$appState.dark,
                    "p-input-filled": this.$primevue.config.inputStyle === "filled",
                    "p-ripple-disabled": !this.$primevue.config.ripple,
                }
            ]
        },
        desktopLogo() {
            if (this.$appState.dark)
                return  '-dark';
            return '';
        }
    },
    watch: {
        $route() {
            const i = this.$route.path.toString().indexOf('/');
            const last = this.$route.path.toString().indexOf('/', i+1);
            this.parentRoute = this.$route.path.toString().slice(0, last);
        }
    }
}
</script>

<style lang="scss">
@import './assets/styles/app/layout.scss';
</style>
